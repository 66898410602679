<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Update Ket Qua</v-card-title>
    <v-row>
      <v-col cols="12"><v-text-field dense outlined label="Date" v-model="item.date"/></v-col>
      <v-col cols="12">
        <v-row class="table-ketqua">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="center-text">
                <v-text-field dense outlined label="Dac biet" v-model="ketqua.dacbiet"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="center-text">
                <v-text-field dense outlined label="Nhat" v-model="ketqua.nhat"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="center-text">
                <v-text-field dense outlined label="Nhi 1" v-model="ketqua.nhi1"/>
              </v-col>
              <v-col cols="6" class="center-text">
                <v-text-field dense outlined label="Nhi 2" v-model="ketqua.nhi2"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Ba 1" v-model="ketqua.ba1"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Ba 2" v-model="ketqua.ba2"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Ba 3" v-model="ketqua.ba3"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Ba 4" v-model="ketqua.ba4"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Ba 5" v-model="ketqua.ba5"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Ba 6" v-model="ketqua.ba6"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bon 1" v-model="ketqua.bon1"/>
              </v-col>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bon 2" v-model="ketqua.bon2"/>
              </v-col>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bon 3" v-model="ketqua.bon3"/>
              </v-col>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bon 4" v-model="ketqua.bon4"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Nam 1" v-model="ketqua.nam1"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Nam 2" v-model="ketqua.nam2"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Nam 3" v-model="ketqua.nam3"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Nam 4" v-model="ketqua.nam4"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Nam 5" v-model="ketqua.nam5"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Nam 6" v-model="ketqua.nam6"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Sau 1" v-model="ketqua.sau1"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Sau 2" v-model="ketqua.sau2"/>
              </v-col>
              <v-col cols="4" class="center-text">
                <v-text-field dense outlined label="Sau 3" v-model="ketqua.sau3"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bay 1" v-model="ketqua.bay1"/>
              </v-col>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bay 2" v-model="ketqua.bay2"/>
              </v-col>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bay 3" v-model="ketqua.bay3"/>
              </v-col>
              <v-col cols="3" class="center-text">
                <v-text-field dense outlined label="Bay 4" v-model="ketqua.bay4"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Close</v-btn>
      <v-btn color="primary" text @click="processUpdate">Update</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import cloneDeep from 'clone-deep';

export default {
  name: 'UpdateKetqua',
  props: ['selectedItem', 'onUpdated', 'onCancel'],
  data: () => ({
    item: {},
    ketqua: {
      dacbiet: '',
      nhat: '',
      nhi1: '',
      nhi2: '',
      ba1: '',
      ba2: '',
      ba3: '',
      ba4: '',
      ba5: '',
      ba6: '',
      bon1: '',
      bon2: '',
      bon3: '',
      bon4: '',
      nam1: '',
      nam2: '',
      nam3: '',
      nam4: '',
      nam5: '',
      nam6: '',
      sau1: '',
      sau2: '',
      sau3: '',
      bay1: '',
      bay2: '',
      bay3: '',
      bay4: '',
    }
  }),
  methods: {
    ...mapActions({
      updateItem: 'lottery_result/update',
    }),
    processUpdate() {
      this.item.result = JSON.stringify(this.ketqua);
      this.updateItem(this.item);
    }
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) {
          this.item = this.cloneDeep(this.selectedItem);
          this.ketqua = JSON.parse(this.item.result);
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
