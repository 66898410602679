<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Detail Ket Qua</v-card-title>
    <div class="table-ketqua">
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Dac Biet</v-col>
        <v-col cols="10" class="result-value">{{ ketqua.dacbiet }}</v-col>
      </v-row>
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Nhat</v-col>
        <v-col cols="10" class="result-value">{{ ketqua.nhat }}</v-col>
      </v-row>
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Nhi</v-col>
        <v-col cols="5" class="result-value">{{ ketqua.nhi1 }}</v-col>
        <v-col cols="5" class="result-value">{{ ketqua.nhi2 }}</v-col>
      </v-row>
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Ba</v-col>
        <v-col cols="10" no-gutters>
          <v-row>
            <v-col cols="4" class="result-value">{{ ketqua.ba1 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.ba2 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.ba3 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.ba4 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.ba5 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.ba6 }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Bon</v-col>
        <v-col cols="10" no-gutters>
          <v-row>
            <v-col cols="3" class="result-value">{{ ketqua.bon1 }}</v-col>
            <v-col cols="3" class="result-value">{{ ketqua.bon2 }}</v-col>
            <v-col cols="3" class="result-value">{{ ketqua.bon3 }}</v-col>
            <v-col cols="3" class="result-value">{{ ketqua.bon4 }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Nam</v-col>
        <v-col cols="10" no-gutters>
          <v-row>
            <v-col cols="4" class="result-value">{{ ketqua.nam1 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.nam2 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.nam3 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.nam4 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.nam5 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.nam6 }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Sau</v-col>
        <v-col cols="10" no-gutters>
          <v-row>
            <v-col cols="4" class="result-value">{{ ketqua.sau1 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.sau2 }}</v-col>
            <v-col cols="4" class="result-value">{{ ketqua.sau3 }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="result-row">
        <v-col cols="2" class="result-name">Giai Bay</v-col>
        <v-col cols="10" no-gutters>
          <v-row>
            <v-col cols="3" class="result-value">{{ ketqua.bay1 }}</v-col>
            <v-col cols="3" class="result-value">{{ ketqua.bay2 }}</v-col>
            <v-col cols="3" class="result-value">{{ ketqua.bay3 }}</v-col>
            <v-col cols="3" class="result-value">{{ ketqua.bay4 }}</v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="onClose">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'DetailKetqua',
  props: ['selectedItem', 'onClose'],
  data: () => ({
    item: {}
  }),
  computed: {
    ketqua() {
      return this.item.result ? JSON.parse(this.item.result) : {}
    }
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item =  JSON.parse(JSON.stringify(this.selectedItem));
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style>
.result-name, .result-value {
  border: 1px solid #ddd;
}
.table-ketqua {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
